import React, { useState, useEffect, useCallback } from 'react';
import styles from '../styles/StickyHeader.module.css';
import { commonUrl, idMapTopic, basePath } from '../pages/api/config';
import SectionHeader from '../widgets/SectionHeader';

const StickyHeader = () => {
  const [pageTitle, setPageTitle] = useState('');
  const [topPath, setTopPath] = useState('');
  const [topID, setTopicID] = useState('');
  const [isShow, setIsShow] = useState(false);
  
  useEffect(() => {
    //add eventlistener to window   
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", onScroll, { passive: true });
    }
  }, []);
  
  //this will be triggered on every scroll action
  const onScroll = useCallback(event => {
    const { pageYOffset, scrollY } = window;
    const pmtHeight = document.getElementById("pmt").offsetTop;
    const riverHeight = document.getElementById("river").offsetTop;
    const bloHeight = document.getElementById("blo").offsetTop;
    const musHeight = document.getElementById("mus").offsetTop;
    const vidHeight = document.getElementById("vid").offsetTop;
    
    //calculation of the sticky header
    if(pmtHeight > 0 && scrollY > (pmtHeight+80) && scrollY < riverHeight){
      //最新文章
      setIsShow(true);
      setPageTitle(idMapTopic['pmt'].topic);
      setTopPath(idMapTopic['pmt'].topPath);
      setTopicID(idMapTopic['pmt'].id);
    }else if(riverHeight > 0 && scrollY > riverHeight && scrollY < bloHeight){
      //栽在溪水旁
      setIsShow(true);
      setPageTitle(idMapTopic['river'].topic);
      setTopPath(idMapTopic['river'].topPath);
      setTopicID(idMapTopic['river'].id);
    }else if(bloHeight > 0 && scrollY > bloHeight && scrollY < musHeight){
      //Blog
      setIsShow(true);
      setPageTitle(idMapTopic['blo'].topic);
      setTopPath(idMapTopic['blo'].topPath);
      setTopicID(idMapTopic['blo'].id);
    }else if(musHeight > 0 && scrollY > musHeight && scrollY < vidHeight){
      //音樂特輯
      setIsShow(true);
      setPageTitle(idMapTopic['mus'].topic);
      setTopPath(idMapTopic['mus'].topPath);
      setTopicID(idMapTopic['mus'].id);
    }else if(vidHeight > 0 && scrollY > vidHeight){
      //影片
      setIsShow(true);
      setPageTitle(idMapTopic['vid'].topic);
      setTopPath(idMapTopic['vid'].topPath);
      setTopicID(idMapTopic['vid'].id);
    }else if(scrollY < pmtHeight){
      //hide the sticky header
      setIsShow(false);
    }
  }, []);
  
  return(<SectionHeader title={pageTitle} path={topPath} headerID={topID} isSticky={true} isShow={isShow}/>);
}

export default StickyHeader;