import React, { useState, useEffect } from 'react';
import Image from 'next/image';
import { Carousel } from 'react-bootstrap';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import styles from '../styles/HomeBanner.module.css';
import * as LIB from '../lib/common';
import { commonUrl } from '../pages/api/config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBolt, faSearch, faAngleRight } from '@fortawesome/free-solid-svg-icons';

const HomeBanner = ({ data }) => {
  const [degree, setDegree] = useState(28);
  const [overall, setOverall] = useState(0);
  const searchIcon = <FontAwesomeIcon icon={faSearch} />;
  const boltIcon = <FontAwesomeIcon icon={faBolt} />;
  const rightIcon = <FontAwesomeIcon icon={faAngleRight} />;

  useEffect(() => {
    getViewCountData();
    getWeatherData();
  }, [])

  const getWeatherData = async () => {
    const res = await fetch(commonUrl.weather);
    const weather = await res.json();
    let degree = weather.temperature.data[1].value;
    setDegree(degree);
  }

  const getTodayDate = () => {
    let newDate = new Date()
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    let separator = '/';
    //return `${date < 10 ? `0${date}`:`${date}`}${separator}${month < 10 ? `0${month}` : `${month}`}${separator}${year}`
    return (
      <>
        <div>{date < 10 ? `0${date}` : date}{separator}{month < 10 ? `0${month}` : month}</div>
        <div className={styles.year}>{year}</div>
      </>
    );
  }

  const getViewCountData = async () => {
    const res = await fetch(commonUrl.headerFooter);
    const resData = await res.json();
    setOverall((resData.overall).toLocaleString('en-US'));
  }

  if (data) {
    return (
      <div className={styles.bgColor}>
        <div className={`viewport`}>
        
          {/* Don't remove this is a new added notice bar, hide it first*/}
          {/* <div className={`d-sm-none ${styles.noticeBar}`}>
            <Container fluid style={{ padding: 0 }}>
              <Row style={{ padding: 0, margin: 0 }}>
                <Col xs={11} style={{ padding: 0}}>
                  <div className={styles.noticeHolder}>
                    <div style={{display:'inline-block'}}><div className={styles.noticeFlag}>{boltIcon}</div></div>
                    <div style={{display:'inline-block'}} className={styles.noticeContent} >SEC主席稱「多數加密幣都是證卷產品，理應...</div>
                  </div>
                </Col>
                <Col xs={1} style={{ padding: 0}}>
                  <div className={styles.searchIcon}>{rightIcon}</div>
                </Col>
              </Row>
            </Container>
          </div> */}
          
          <Container fluid style={{ padding: 0 }}>
            <Row style={{ padding: 0, margin: 0 }}>
              <Col xs={12} md={12} lg={7} className={styles.slider}>
                <Carousel>
                  {
                    data.left && data.left.map((banner, index) => (
                      <Carousel.Item key={index}>
                        <a href={banner.a}>
                          {
                            banner.img && <Image
                              className={styles.leftBanner}
                              src={banner.img}
                              alt="圖片"
                              layout="responsive"
                              width={1920}
                              height={1080}
                            // priority={true}
                            />
                          }
                          {
                            banner.text && <Carousel.Caption>
                              <p>{banner.text}</p>
                            </Carousel.Caption>
                          }
                        </a>
                      </Carousel.Item>
                    ))
                  }
                </Carousel>
              </Col>

              <Col lg={5} className={`d-none d-lg-block d-xl-block ${styles.rightSide}`} >
                <Container style={{ backgroundColor: 'f2f2f2', padding: 0 }}>
                  <Row style={{ padding: 0, paddingBottom: 5 }}>
                    <Col lg={4} className={styles.rightTop}>
                      <div>
                        <>
                          <Image
                            src={LIB.getBasepath('/images/banner/123.png')}
                            alt="圖片"
                            width={130}
                            height={130}
                          // layout="responsive" 
                          />
                          <div className={styles.viewCountNum}>
                            <span className={styles.viewCountTitle}>總瀏覽</span><br />{overall}人次</div>
                        </>
                      </div>
                    </Col>
                    <Col lg={8} style={{ padding: 0 }}>
                      {
                        data.rightTop[0].img && <a href={data.rightTop[0].a}><Image
                          className="d-block w-100"
                          src={data.rightTop[0].img}
                          alt="圖片"
                          layout="responsive"
                          width={1920}
                          height={1080}
                        /></a>
                      }
                    </Col>
                  </Row>
                  <Row style={{ backgroundColor: '#fff' }}>
                    <Col lg={8} style={{ padding: 0 }}>
                      {
                        data.rightBottom[0].img && <a href={data.rightBottom[0].a}>
                          <Image
                            className="d-block w-100"
                            src={data.rightBottom[0].img}
                            alt="圖片"
                            layout="responsive"
                            width={1920}
                            height={1142}
                          />
                        </a>
                      }
                    </Col>
                    <Col lg={4} className={styles.rightBottom}>
                      <div style={{ padding: 0 }}>
                        <div className={styles.today}>{getTodayDate()}</div>
                        <div className={styles.divider1}></div>
                        <div className={styles.weather}>
                          <span className={styles.bigDot}>⬤ ⬤</span>
                          <span>溫度 {degree} °C</span>
                        </div>
                        <div className={styles.divider2}></div>
                        <div className={styles.churchIcon}>
                          <Image
                            src={LIB.getBasepath('/images/banner/churchofzion.png')}
                            alt="圖片"
                            width={120}
                            height={30}
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    );
  } else {
    return <></>;
  }

}

export default HomeBanner;