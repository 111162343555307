import React, { useState, useEffect, useCallback } from 'react';
import Layout from '../components/Layout';
import Header from '../components/Header';
import StickyHeader from '../components/StickyHeader';
import Footer from '../components/Footer';
import LatestPost from '../components/LatestPost';
import Promotion from '../components/Promotion';
import HomeBanner from '../components/HomeBanner';
import River from '../components/River';
import { commonUrl } from './api/config';
import * as LIB from '../lib/common';
import HtmlHead from '../components/Head';
import Head from 'next/head';

/*
-This is the entry point the whole website
-For global css, edit in glocals.css
  
Main Color
Blue    #0077AB, #3392BC, #66ADCD, #CCE4EE
Orange  #F39800, #F5AC33, #F8C166, #FDEACC
purple  #923F93, #A865A9, #BE8CBE, #E9D9E9
Gray    #000000, #333333, #666666, #CCCCCC
*/

export default function Home({ latestData, riverData, blogData, menuData, musicData, bttmData, videoData, homeBannerData }) {
  const [headData, setHeadData] = useState([]);
  const [promotionData, setPromotionData] = useState([]);
  
  
  useEffect(() => {
    //init data
    getHeadData();
    updateViewCount();
    fetchPromotionData();
  }, [])

  const getHeadData = () => {
    let data = [];
    data['title'] = '錫安日報';
    setHeadData(data);
  }

  const updateViewCount = async () => {
    await fetch(commonUrl.viewCount);
  }

  const fetchPromotionData = async () => {
    fetch(commonUrl.promotionBars, {
    }).then(response => response.json()
    ).then(data => {
      setPromotionData(data);    
    });
  }

  return (
    <Layout >
      {/* <HtmlHead data={headData}/> */}
      <Head>
        <title>錫安日報</title>
        <link rel="icon" type="image/x-icon" href="/favicon.ico" />
        <link rel="preconnect" href="https://fonts.googleapis.com"/>
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin/>
        <link href="https://fonts.googleapis.com/css2?family=Hind&display=swap" rel="stylesheet"/>
        <meta charSet="utf-8" />
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
        <meta name='keywords' content='錫安日報' />
        <meta name='description' content='錫安日報，透過網絡發放及分享基督教錫安教會的信息、詩歌、創作及見証，認識我們從這裡開始。' />
        <meta property='og:type' content='article' />
        <meta property='og:title' content='錫安日報 ZD3' />
        <meta property='og:description' content='錫安日報 ZD3' />
        <meta property='og:url' content='https://www.ziondaily.com' />
        <meta property='og:site_name' content='鍚安日報 ZD3' />
      </Head>

      <Header topicData={menuData} />
      <StickyHeader topicData={menuData} />
      <HomeBanner data={homeBannerData} />
      <LatestPost data={latestData.data} pageTitle={"最新文章"} topPath={'/news/pmt/最新文章'} topicID='pmt'/>
      <Promotion data={promotionData} row={0} topicID='pmt1'/>
      <River data={riverData.data} dates={riverData.dates} />
      <Promotion data={promotionData} row={1} topicID='pmt2'/>
      <LatestPost data={blogData.data} pageTitle={"BLOG"} topPath={'/topics/blo/Blog'} topicID='blo'/>
      <Promotion data={promotionData} row={2} topicID='pmt3'/>
      <LatestPost data={musicData.data} pageTitle={"音樂特輯"} topPath={'/news/mus/音樂特輯'} topicID='mus'/>
      <Promotion data={promotionData} row={3} topicID='pmt4'/>
      {/* <LatestPost data={bttmData.data} pageTitle={"BTTM"} topPath={'/news/btm/BTTM'} topicID='btm'/> */}
      <LatestPost data={videoData.data} pageTitle={"影片"} topPath={'/news/vid/影片'} topicID='vid'/>
      <Promotion data={promotionData} row={4} topicID='pmt5'/>
      <Footer />
    </Layout>
  )
}

export async function getServerSideProps() {

  // Fetch data from external API
  const [latestRes, blogRes, riverRes, menuRes, musicRes, bttmRes, videoRes, homeBannerRes] = await Promise.all([
    fetch(commonUrl.promotion),
    fetch(commonUrl.blog),
    fetch(commonUrl.river),
    fetch(commonUrl.menu),
    fetch(commonUrl.music),
    fetch(commonUrl.bttm),
    fetch(commonUrl.video),
    fetch(commonUrl.homeBanner)
  ]);

  const [latestData, blogData, riverData, menuData, musicData, bttmData, videoData, homeBannerData] = await Promise.all([
    latestRes.json(),
    blogRes.json(),
    riverRes.json(),
    menuRes.json(),
    musicRes.json(),
    bttmRes.json(),
    videoRes.json(),
    homeBannerRes.json()
  ]);

  // Pass data to the page via props
  return { props: { latestData, blogData, riverData, menuData, musicData, bttmData, videoData, homeBannerData } }
}