import React, { useState, useEffect } from 'react';
import Head from 'next/head';

const HtmlHead = ({ data }) => {


  useEffect(() => {
    // console.log('htmlhead=', data);
  }, []);

  return (
    <Head>
      <title>{data.title && data.title != '' ? data.title : '錫安日報'}</title>
      <link rel="icon" type="image/x-icon" href="/favicon.ico" />
      <meta charSet="utf-8" />
      <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      <meta name='keywords' content={data.title && data.title != ''? data.title : '錫安日報'} />
      <meta name='description' content={data.description && data.description != ''? data.description : '錫安日報'} />
      <meta property='og:type' content='article' />
      <meta property='og:title' content={data.title && data.title != ''? data.title : '錫安日報'} />
      <meta property='og:description' content={data.description && data.description != ''? data.description : '錫安日報'} />
      {data.image && <meta property='og:image' content={data.image} />}
      <meta property='og:url' content={data.url} />

      <meta property='og:site_name' content='鍚安日報' />
    </Head>
  );

};

export default HtmlHead;
