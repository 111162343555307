
import React, { useState, useEffect } from 'react';
import Image from 'next/image';
import styles from '../styles/LatestPost.module.css';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import SectionHeader from "../widgets/SectionHeader";
import Link from 'next/link';
import _ from "lodash";
import * as LIB from '../lib/common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/fontawesome-free-regular';

const LatestPost = ({ data, pageTitle, topPath, topicID }) => {
  const clockIcon = <FontAwesomeIcon icon={faClock} />;
  // useEffect(() => {
  //   //console.log('promotion=', data);
  //   // var target = document.getElementById("pmt");
  //   const box = document.getElementById('pmt1')
  //   // console.log(target.offsetTop);
  //   box.addEventListener("scroll", () =>{
  //     const vertical = box.scrollTop    // Getting vertical scrollbar position of a div
  //   const horizontal = box.scrollLeft    // Getting vertical scrollbar position of a div

  //   console.log(`Vertical scrollbar Position of a div: ${vertical}`)
  //   console.log(`Horizontal scrollbar Position of a div: ${horizontal}`)
  //   });
    
  // }, [])

  const renderImg = (imgPath) => {
    if (imgPath == "https://ziondaily.com/2.0/img_s/default_news_thumbnail.jpg" || imgPath == '') {
      let typeID = '03a'
      imgPath = LIB.getBasepath(`/images/topic/${typeID}.jpg`);
    }

    return (
      <Image
        src={imgPath}
        layout="responsive"
        height={220}
        width={400}
        alt="圖片"
        className={styles.img}
      />
    );
  }

  const renderLink = (post) => {

    if (topicID && topicID == 'vid') {
      return ('https://www.youtube.com/watch?v=' + post.code);
    } else if (post.id) {
      return (LIB.getBasepath(`/posts/${post.id}/` + encodeURIComponent(post.title.replace(/ /g, ''))));
    } else if (post.code) {
      return ('https://www.youtube.com/watch?v=' + post.code);
    } else {
      return ('javascript:void(0)');
    }
  }

  const renderAtagTarget = (post) => {
    if(topicID && topicID == 'vid'){
      return ('_blank');
    } else if (post.id) {
      return ('_self');
    } else if (post.code) {
      return ('_blank');
    } else {
      return ('_self');
    }
  }

  return (
    <div className="viewport">
      <SectionHeader title={pageTitle} path={topPath} headerID={topicID}/>
      <Container fluid>
        <Row>
          {data && data.slice(0, 8).map((post, index) => (
            <Col key={index} md={6} lg={3} className={styles.colum}>
              <a href={renderLink(post)} target={renderAtagTarget(post)}>
                <div className={styles.card}>
                  {renderImg(post.thumbnail)}
                  <Container fluid>
                    <Row>
                      <Col className={styles.titleColum}>
                        <div className={styles.bottomArea}>
                          <div className={styles.title}>{post.title}</div>
                          <div className={styles.excerpt}>{post.excerpt}</div>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} className='d-block d-sm-none'>
                        <div className={styles.divider}></div>
                      </Col>
                    </Row>
                    <Row className={styles.infoRow}>
                      <Col className={styles.fullColum}>
                        <div className={styles.date}>{clockIcon} {post.post_date.substring(0, 10)}</div>
                      </Col>
                      <Col className={styles.fullColum}>
                        {/* <div className={styles.category}>{post.cate_title ? post.cate_title : '精選推介'}</div> */}
                        <div className={styles.category}>{post.topic_title ? post.topic_title : '精選推介'}</div>
                      </Col>
                    </Row>
                  </Container>
                </div>
              </a>
            </Col>
          ))
          }
        </Row>
      </Container>
    </div>
  );
}




export default LatestPost;