import React, { useState, useRef } from 'react';
import Image from 'next/image';
import styles from '../styles/River.module.css';
import SectionHeader from "../widgets/SectionHeader";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ProgressBar from 'react-bootstrap/ProgressBar';
import ReactPlayer from 'react-player';
import Form from 'react-bootstrap/Form';
import Player from './Player';
import Link from 'next/link';
import _ from "lodash";
import * as LIB from '../lib/common';

const River = ({ data }) => {

  //console.log('river data=', data);

  const renderRiverRow = (data) => {
    if (data) {
      return (
        data.map((data, index) => (
          <a href={LIB.getBasepath(`/posts/${data.id}/` + encodeURIComponent(data.title.replace(/ /g, '')))} key={index} className={styles.link}>
            <div className={styles.riverRow}>
              <Container className={styles.riverContainer}>
                <Row className={styles.row}>
                  <Col xs={1} className={styles.riverRowLabelHolder}>
                    <div className={styles.riverRowLabel}>栽</div>
                  </Col>
                  <Col className={styles.fullColum}>
                    <div className={styles.riverRowContent}>
                      <div className={styles.riverRowTitle}>
                        {data.title}
                      </div>
                      <div className={styles.riverRowDate}>
                        {data.post_date.substring(0, 10)}
                      </div>
                      <div className={styles.riverRowExcerpt}>
                        {data.excerpt}
                      </div>
                      <div className={styles.riverRowTag}>
                        詳細資料
                      </div>
                    </div>
                  </Col>
                </Row>
              </Container>
          </div>
          </a>
        ))
      );
    }
  }

  const renderFirstItem = (data) => {
    return (
      <Col xs={12} md={6} lg={4} className={styles.colum}>
        <a href={LIB.getBasepath(`/posts/${data.id}/` + encodeURIComponent(data.title.replace(/ /g, '')))}>
          <div className={styles.card}>
            <Image
              src={data.thumbnail}
              layout="responsive"
              height={220}
              width={400}
              alt="圖片"
            />
            <Container fluid >
              <Row>
                <Col className={styles.titleColum}>
                  <div className={styles.bottomArea}>
                    <div className={styles.title}>{data.title}</div>
                    <div className={styles.excerpt}>{data.excerpt}</div>
                  </div>
                </Col>
              </Row>
              <Row className={styles.infoRow}>
                <Col className={styles.fullColum}><div className={styles.date}>{data.post_date.substring(0, 10)}</div></Col>
                <Col className={styles.fullColum}>
                  <div className={styles.category}>栽</div>
                </Col>
              </Row>
            </Container>
          </div>
        </a>
      </Col>
    );
  }

  if (data) {
    let firstRow = data[0];
    let secondRow = [data[1], data[2]];
    let thirdRow = [data[3], data[4]];
    let class4colum = `d-none d-lg-block d-xl-block ${styles.colum}`;

    return (
      <div className="viewport" id='river'>
        <SectionHeader title={"栽在溪水旁"} path={'/topics/02a/栽在溪水旁'} />
        <Container fluid>
          <Row>
            {renderFirstItem(firstRow)}
            <Col lg={4} md={6} className={styles.colum}>
              <div className={styles.riverHolder}>
                {renderRiverRow(secondRow)}
              </div>
            </Col>
            <Col lg={4} className={class4colum}>
              <div className={styles.riverHolder}>
                {renderRiverRow(thirdRow)}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );

  }
  else {
    return (<></>);
  }
}

export default River;