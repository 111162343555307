
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import styles from '../styles/Promotion.module.css';
import Image from 'next/image';
import parse from 'html-react-parser'


const Promotion = ({ data, row }) => {

  //
  // if API do not provide data for this row, then just render empty space
  //
  if (row >= data.length || data[row].length == 0) {
    return (<div></div>);
  }
  //
  // otherwise, render the promotion row using the specified template
  //
  else {
    let rowData = data[row];
    
    // ================================================================================================================================
    // BTTM, multiple-image alike but with the social media icons bar
    // ================================================================================================================================
    if (rowData.template == 'bttm') {
      //let boxsize = 12 / rowData.cols.length;      
      return (
      <div className={`${styles.promotion_bttm}`} id='bttm'>

        <div className={`${styles.bttm_promotion_title}`}>
          Back To The Music
          &nbsp; &nbsp; &nbsp; 
          <a href='https://www.youtube.com/c/BackToTheMusic' target='_blank'><i class="fab fa-youtube"></i></a>
          &nbsp;
          <a href='https://www.facebook.com/Back-To-The-Music-113965263802955' target='_blank'><i className="fab fa-facebook-square"></i></a>
          &nbsp;
          <a href='https://twitter.com/backtothemusic7' target='_blank'><i class="fab fa-twitter"></i></a>
          &nbsp;
          <a href='https://www.instagram.com/back_to_the_music_/' target='_blank'><i class="fab fa-instagram-square"></i></a>
          &nbsp;
        </div>
        
        {
                rowData.cols.map(function(item, index) {          
            return (
            <a key={index} href={item.url} target='_blank'>
              <div className={`${styles.bttm_item}`}>
                <img src={item.img} />
                <div class='title'>{item.title}</div>
              </div>
            </a>
            );
          })
        }
        
      </div>
      );
    }
    // ================================================================================================================================
    // ZNSE, multiple-image alike but with the social media icons bar
    // ================================================================================================================================
    else if ( rowData.template == 'znse'){
    
      //let boxsize = 12 / rowData.cols.length;      
      return (
      <div className={`${styles.promotion_bttm}`} id='znse'>
        <div className={`${styles.znse_promotion_title}`}>
          Zion New Song E
          &nbsp; &nbsp; &nbsp; 
          <a href='https://www.youtube.com/channel/UCJhHl-69jwbPRe0SzLLAPBA' target='_blank'><i class="fab fa-youtube"></i></a>
          &nbsp;
          <a href='https://www.facebook.com/profile.php?id=100075596239811' target='_blank'><i className="fab fa-facebook-square"></i></a>
          &nbsp;
          <a href='https://twitter.com/zionnewsonge' target='_blank'><i class="fab fa-twitter"></i></a>
          &nbsp;
          <a href='https://www.instagram.com/zionnewsonge/' target='_blank'><i class="fab fa-instagram-square"></i></a>
          &nbsp;
        </div>
      
        {
          rowData.cols.map(function(item, index) {          
            return (
            <a key={index} href={item.url} target='_blank'>
              <div className={`${styles.bttm_item}`}>
                <img src={item.img} />
                <div class='title'>{item.title}</div>
              </div>
            </a>
            );
          })
        }
      </div>
      );
      
    }
    // ================================================================================================================================
    // multiple-image
    // ================================================================================================================================
    else if (rowData.template == 'multiple-image') {
      let boxsize = 12 / rowData.cols.length;
      
      return (
        <div className={`${styles.fullWidth3}`} id='multiple-image'>
          <div className="viewport" >
            <Container fluid className={styles.full}>
              <Row className={styles.zeroPadding}>
                <Col className={styles.zeroPadding}>
                  <div className={styles.header}>{rowData.headline}</div>
                </Col>
              </Row>
              <Row className={styles.zeroPadding}>            
              {
                rowData.cols.map(function(item, index) {
                  return (
                  <Col lg={boxsize} key={index} className={styles.zeroPadding}>
                    <a target='_blank' href={item.url}>
                      <Image
                        src={item.img}
                        layout="responsive"
                        width={480}
                        height={360}
                        alt={item.title}
                        className={styles.img}
                      />
                    </a>
                  </Col>
                  );
                })
            }
            </Row>
            </Container>
          </div>
        </div>
      );
    }
    // ================================================================================================================================
    // 2 - 4 video boxes
    // ================================================================================================================================
    else if (rowData.template == 'multiple-video') {
      let boxsize = 12 / rowData.cols.length;

      return (
        <div className={`${styles.fullWidth3}`} id='multiple-video'>
          <div className="viewport" >
            <Container fluid className={styles.full}>
              <Row className={styles.zeroPadding}>
                <Col className={styles.zeroPadding}>
                  <div className={styles.header}>{rowData.headline}</div>
                </Col>
              </Row>
              <Row className={styles.zeroPadding}>             
                {
                  rowData.cols.map(function(item, index) {
                    return (
                    <Col lg={boxsize} key={index} className={styles.zeroPadding}>
                      <div className={styles.videoResponsive}>
                        <iframe
                          src={item.iframe}
                          frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen
                          title={item.title}
                        />
                      </div>
                    </Col>
                    );
                  })
                }  
              </Row>
            </Container>
          </div>
        </div> 
      );
    } 
    // ================================================================================================================================
    // single content with a video on the left side
    // ================================================================================================================================
    else if (rowData.template == 'single-video-with-content') {
      let item = rowData.cols[0];

      return (
        <div className={`${styles.fullWidth}`}>
          <div className="viewport" >
            <Container fluid className={styles.full}>
              <Row className={styles.zeroPadding}>
                <Col className={styles.zeroPadding}>
                  <div className={styles.header}>{item.headline}</div>
                </Col>
              </Row>
              <Row className={styles.zeroPadding}>
                <Col lg={7} className={styles.zeroPadding}>
                  <div className={styles.videoResponsive}>
                    <iframe
                      src={item.iframe}
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                      title={item.title}
                    />
                  </div>
                </Col>
                <Col lg={5} className={styles.zeroPadding}>
                  <div className={styles.subtitle}>
                    <h5 style={{ color: '#000' }}>【 {item.title} 】</h5>
                    {parse(item.subtitle)}
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      );
    }
    // ================================================================================================================================
    // podcast covers links to podcast page
    // ================================================================================================================================
    else if (rowData.template == 'podcast-covers') {
      let boxsize = 12 / rowData.cols.length;

      return (
        <div className={`${styles.fullWidth0}`}>
          <div className="viewport" >
            <Container fluid className={styles.full}>
              <Row className={styles.zeroPadding}><Col className={styles.zeroPadding}><div className={styles.header}>{rowData.headline}</div></Col></Row>
              <Row className={styles.zeroPadding}>
                {
                  rowData.cols.map(function(item, index) {
                    return (
                    <Col lg={boxsize} key={index} className={styles.zeroPadding}>
                      <a target='_blank' href={item.url}>
                        <Image
                          src={item.img}
                          layout="responsive"
                          height={200}
                          width={200}
                          alt={item.title}
                          className={styles.img}
                        />
                      </a>
                    </Col>
                    );
                  })
                }
              </Row>
            </Container>
          </div>
        </div>
      );
    }
    // ================================================================================================================================
    // unknow template, show nothing
    // ================================================================================================================================
    else {
      return ('<div></div>');
    }
  }
}

export default Promotion;