import React, { useState, useEffect } from 'react';
import styles from '../styles/SectionHeader.module.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Link from 'next/link';
import { useRouter } from "next/router";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import * as LIB from '../lib/common';

const SectionHeader = ({ title, path, headerID, isSticky, isShow}) => {
  const moreIcon = <FontAwesomeIcon icon={faAngleRight}/>;

  if (title && path && isShow !== false) {
    return (
      <Container fluid className={`${styles.holder} ${isSticky === true ? styles.stickyHeader : ''}`} id={(isSticky === true ? null : headerID)}>
        <a href={LIB.getBasepath(path)}>
          <Row>
            <Col className={styles.fullWidth}>
              <div className={styles.header}>{title}</div>
            </Col>
            <Col xs={3} className={`${styles.fullWidth} ${styles.viewMore}`}>
              <div className={styles.viewMoreLabel}>更多 &#5171;</div>
            </Col>
          </Row>
        </a>
      </Container>
    );
  }
  else {
    return (<></>);
  }

}

export default SectionHeader;